import axios from 'axios';
import he from 'he';
import React, { useEffect, useState } from 'react';
import PostModal from './modal';

// Logos
import Pmf from '../assets/img/logos/Pmf.svg';
import Adr from '../assets/img/logos/adr.svg';
import Ba from '../assets/img/logos/ba.svg';
import Bla from '../assets/img/logos/bla.svg';
import Ff from '../assets/img/logos/ff.svg';
import Froya from '../assets/img/logos/froya.svg';
import Gp from '../assets/img/logos/gp.svg';
import Hitra from '../assets/img/logos/hitra.svg';
import Inn from '../assets/img/logos/inn.svg';
import Kp from '../assets/img/logos/kp.svg';
import Mn24 from '../assets/img/logos/mn24.svg';
import Opp from '../assets/img/logos/opp.svg';
import S24 from '../assets/img/logos/s24.svg';
import St from '../assets/img/logos/st.svg';
import Tb from '../assets/img/logos/tb.svg';

const FilterList = ({ activeMonth, selectedCategories, currentMonth, months }) => {
	const [posts, setPosts] = useState({ bilag: [], tema: [], kampanje: [], merkedager: [] });
	const [selectedPost, setSelectedPost] = useState(null);

	/** Parses both YYYY-MM-DD and YYYYMMDD formats */
	const formatDate = (dateStr) => {
		if (!dateStr) return null;
		let year, month, day;

		if (dateStr.includes('-')) {
			// Handles "YYYY-MM-DD"
			[year, month, day] = dateStr.split('-').map(Number);
		} else if (dateStr.length === 8) {
			// Handles "YYYYMMDD"
			year = parseInt(dateStr.substring(0, 4), 10);
			month = parseInt(dateStr.substring(4, 6), 10);
			day = parseInt(dateStr.substring(6, 8), 10);
		} else {
			return null;
		}

		return {
			year,
			month,
			day,
			formatted: `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}`,
			sortable: `${year}${month}${day}`,
		};
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				let page = 1;
				let hasMore = true;
				let allPosts = [];
				const categorizedPosts = { bilag: [], tema: [], kampanje: [], merkedager: [] };

				while (hasMore) {
					const response = await axios.get(
						`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts?per_page=100&page=${page}`
					);

					if (response.data.length === 0) {
						hasMore = false;
						break;
					}

					allPosts = allPosts.concat(response.data);

					const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
					if (page >= totalPages) {
						hasMore = false;
					} else {
						page++;
					}
				}

				for (const post of allPosts) {
					const omrade = post.acf.omrade;
					if (!categorizedPosts[omrade]) continue;

					const dateInfo = formatDate(post.acf.dato);
					const postAlreadyAdded = new Set();

					if (!dateInfo) continue;

					// Add the original post (main date)
					const key = `${dateInfo.year}-${dateInfo.month}-${dateInfo.day}`;
					if (!postAlreadyAdded.has(key)) {
						categorizedPosts[omrade].push({
							...post,
							date: dateInfo,
						});
						postAlreadyAdded.add(key);
					}

					// Handle "gjentagende_aktivitet" repeater field
					if (post.acf.gjentagende_aktivitet && Array.isArray(post.acf.gjentagende_aktivitet)) {
						for (const repeatEntry of post.acf.gjentagende_aktivitet) {
							if (repeatEntry.velg_dato) {
								const repeatDateInfo = formatDate(repeatEntry.velg_dato);

								if (repeatDateInfo) {
									const repeatKey = `${repeatDateInfo.year}-${repeatDateInfo.month}-${repeatDateInfo.day}`;
									if (!postAlreadyAdded.has(repeatKey)) {
										categorizedPosts[omrade].push({
											...post,
											date: repeatDateInfo,
										});
										postAlreadyAdded.add(repeatKey);
									}
								}
							}
						}
					}
				}

				setPosts(categorizedPosts);
			} catch (error) {
				console.error('Error fetching posts:', error);
			}
		};

		fetchData();
	}, [activeMonth]);

	const getFilteredPosts = (postsArray, activeMonth, selectedCategories, months) => {
		const currentYear = new Date().getFullYear();
		const globalCategoryId = 3;

		const filtered = postsArray.filter((post) => {
			if (!post.date) return false;

			// Filter by year
			if (post.date.year !== currentYear) return false;

			// Check if the post matches the selected month
			const isCorrectMonth =
				activeMonth === months.length || post.date.month === activeMonth;

			return isCorrectMonth;
		});

		const sorted = filtered.sort((a, b) => a.date.sortable.localeCompare(b.date.sortable));

		return sorted;
	};

	function getLogo(category) {
		const logos = { 3: Pmf, 4: Adr, 5: Tb, 6: Mn24, 10: Bla, 11: Ba, 12: Ff, 13: Hitra, 14: Froya, 15: Inn, 16: Opp, 17: St, 18: S24, 19: Kp, 20: Gp };
		return logos[category] || Adr;
	}

	const fetchPostDetails = async (postId) => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/posts/${postId}`);
			setSelectedPost(response.data);
		} catch (error) {
			console.error('Error fetching post details:', error);
		}
	};

	return (
		<section className='filterList w-full 2xl:w-3/4 mx-auto mt-8 px-6 lg:px-12 2xl:px-0'>
			<div className='content flex flex-col xl:flex-row justify-between'>
				{['bilag', 'tema', 'kampanje', 'merkedager'].map((category) => (
					<div key={category} className={category}>
						<h3 className='text-2xl'>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
						<div className='list mt-4'>
						{getFilteredPosts(posts[category], activeMonth, selectedCategories, months).map(
							(post, index) => (
								<div 
									key={index} 
									className='item flex flex-row justify-between items-center'
									onClick={(e) => {
										e.preventDefault(); // Prevent default behavior
										setSelectedPost(post); // Set the post for the modal
									}}
									style={{ cursor: 'pointer' }} // Add pointer cursor to indicate clickability
								>
									<a href="#" rel="noopener noreferrer">
										<h2>{post.title?.rendered ? he.decode(post.title.rendered) : 'Ukjent tittel'}</h2>
										<h2>{post.date.formatted}</h2>
									</a>
									<img src={getLogo(post.categories[0])} alt='' />
								</div>
							)
						)}
						</div>
					</div>
				))}
			</div>
			{selectedPost && (
				<PostModal
					post={selectedPost}
					onClose={() => setSelectedPost(null)}
				/>
			)}
		</section>
	);
};

export default FilterList;
